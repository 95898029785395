import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

export class MockLocalStorage implements Storage {
    _Data: { [key: string]: any } = {};
    [name: string]: any;

    get length(): number {
        return Object.keys(this._Data).length    
    }

    clear(): void {
        this._Data = {};
    }

    getItem(key: string): string | null {
        return this._Data[key] || null
    }

    key(index: number): string | null {
        return Object.keys(this._Data)[index]
    }

    removeItem(key: string): void {
        delete this._Data[key]
    }
    setItem(key: string, value: string): void {
        this._Data[key] = value
    }
}

@Injectable({providedIn: 'root'})
export class StorageService {
    localStorage: Storage;
    sessionStorage: Storage;

    constructor(private platform: PlatformService) {
        this.localStorage = this.platform.isBrowser() ? localStorage : new MockLocalStorage();
        this.sessionStorage = this.platform.isBrowser() ? sessionStorage : new MockLocalStorage();
    }
}