import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IProfile, IUser } from '../models/model';
import { BaseService } from './base.service';
import { StorageService } from './local-storage.service';

export interface ILogin {
    token: string,
    user: IUser
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService {

    private user: IUser | null = null;
    public currentUser = new BehaviorSubject<IUser | null>(null);

    private apiUrl = environment.apiUrl + '/auth';

    constructor(
        protected override http: HttpClient,
        private router: Router,
        private storageService: StorageService,
    ) {
        super(http);

        const user = JSON.parse(this.storageService.localStorage.getItem('user') as string)
        this.currentUser.next(user)
    }

    login(email: string, password: string) {
        return this.post<ILogin>(this.apiUrl + '/login', { email, password })
            .pipe(map(response => this.setUserData(response.data)));
    }

    signUp(data: IProfile) {
        return this.post<ILogin>(this.apiUrl + '/signup', data)
            .pipe(map(response => this.setUserData(response.data)));
    }

    googleSignUp(data: ILogin){
      return this.setUserData(data);
    }

    googleCreateProfile(data: IProfile){
      return this.post<ILogin>(this.apiUrl + '/google-create-profile', data).pipe(
        map((response) => this.setUserData(response.data))
      );
    }

    setUserData(data: ILogin) {
        const tokenData = data.token;
        const userData = data.user;

        const fullUserData = { ...userData, token: tokenData };
        this.currentUser.next(fullUserData);

        this.storageService.localStorage.setItem('user', JSON.stringify(fullUserData))

        return userData;
    }

    forgotPass(email: string) {
        return this.post(this.apiUrl + '/forgot-password', { email })
    }

    resetPass(data: {
        email: string,
        password: string,
    }) {
        return this.post(this.apiUrl + '/reset-password', data)
    }

    logout() {
        this.storageService.localStorage.removeItem('user');
        this.currentUser.next(null);
        this.router.navigate(['/']);
    }

}