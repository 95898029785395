import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {
    constructor(@Inject(PLATFORM_ID) private platformId: object) { }

    get window(): Window | null {
        if (isPlatformBrowser(this.platformId)) {
            return window
        } else {
            return null
        }
    }

    isServer() {
        return isPlatformServer(this.platformId)
    }

    isBrowser() {
        return isPlatformBrowser(this.platformId)
    }
}